import Vue from "vue";
import VueRouter from "vue-router";

import store from '@/store'
import { getToken } from '@/utils/auth'

import Login from "../views/login";
import Main from "../views/main";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "实验室安全准入教学中心登录",
      },
    },
    {
      path: "/",
      name: "main",
      component: Main,
      meta: {
        title: "实验室安全准入教学中心",
      },
    },
  ],
});

const whiteList = ['/login']// no redirect whitelist

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

	if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (store.getters.roles.length === 0) { // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => { // 拉取user_info
					next({ path: '/' })
        }).catch(() => {
          // store.dispatch('LogOut').then(() => {
          //   location.reload() // 为了重新实例化vue-router对象 避免bug
          // })
        })
      } else {
        next()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
    }
	}
});
export default router;
